import additionalEN from '../../translations/en/additional.json'
import translationEN from '../../translations/en/translations.json'
import wordsEN from '../../translations/en/words.json'
import additionalFR from '../../translations/fr/additional.json'
import translationFR from '../../translations/fr/translations.json'
import wordsFR from '../../translations/fr/words.json'

const resources = {
    en: {
        translations: translationEN,
        words: wordsEN,
        additional: additionalEN
    },
    fr: {
        translations: translationFR,
        words: wordsFR,
        additional: additionalFR
    }
}

export default resources
